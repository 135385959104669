<script setup lang="ts">
import { QMenu, QList } from 'quasar';
import { provide, toRef } from 'vue';

import { wsMenuInjectionKey } from './ws-menu-injection-key';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  minWidth?: string;
  maxHeight?: string;
  aid: string;
}>();

defineSlots<{
  default(): unknown;
}>();

provide(wsMenuInjectionKey, {
  minWidth: toRef(() => props.minWidth),
});
</script>

<template>
  <QMenu
    auto-close
    fit
    :max-height="maxHeight"
    :offset="[0, 6]"
    class="shadow"
    :aid="aid"
  >
    <QList class="py-2.5">
      <slot />
    </QList>
  </QMenu>
</template>
