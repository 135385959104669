<script setup lang="ts">
import { QForm } from 'quasar';

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits<{
  submit: [event: Event];
}>();
</script>
<template>
  <QForm @submit="emit('submit', $event)">
    <slot></slot>
  </QForm>
</template>
