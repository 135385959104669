<script setup lang="ts">
import { QSeparator } from 'quasar';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    separator?: boolean;
  }>(),
  {
    separator: true,
  }
);
</script>

<template>
  <QSeparator v-if="props.separator" color="grey-100" />

  <div class="ws-dialog-actions">
    <slot></slot>
  </div>
</template>

<style scoped>
.ws-dialog-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 14px 24px;
}
</style>
