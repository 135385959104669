<script lang="ts">
let wsCheckboxId = 0;
</script>

<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
});

const modelValue = defineModel<boolean | unknown[]>();

defineProps<{
  label?: string;
  value?: unknown;
  disabled?: boolean;
  indeterminate?: boolean;
  aid: string;
}>();

const id = `ws-checkbox-${wsCheckboxId++}`;
</script>

<template>
  <div class="flex items-center">
    <input
      :id="id"
      v-model="modelValue"
      type="checkbox"
      :value="value"
      :disabled="disabled"
      class="peer h-4 w-4 appearance-none rounded-sm text-primary focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-1 disabled:!opacity-100"
      :class="
        indeterminate
          ? 'bg-checkbox-indeterminate disabled:bg-checkbox-indeterminate-disabled'
          : 'bg-checkbox-unchecked checked:bg-checkbox-checked disabled:bg-checkbox-unchecked-disabled disabled:checked:bg-checkbox-checked-disabled'
      "
      :aid="aid"
    />

    <label
      v-if="label"
      :for="id"
      class="ms-3 text-sm peer-disabled:text-gray-400"
    >
      {{ label }}
    </label>
  </div>
</template>
