<script setup lang="ts">
import { ListboxOption } from '@headlessui/vue';
import { inject } from 'vue';

import { wsSelectInjectionKey, OptionValue } from './ws-select-injection-key';

defineOptions({
  inheritAttrs: false,
});

withDefaults(
  defineProps<{
    value?: OptionValue;
    disabled?: boolean;
  }>(),
  {
    value: undefined,
  }
);

const context = inject(wsSelectInjectionKey);

if (!context) {
  throw new Error('WsSelectOption must be used within a WsSelect');
}

const { optionKey, checkboxes, aid } = context;
</script>

<template>
  <ListboxOption
    v-slot="{ selected }"
    :value="value"
    :disabled="disabled"
    class="flex cursor-default select-none flex-nowrap items-center gap-x-2.5 px-5 py-2.5 text-start ui-active:bg-gray-100"
    :class="$attrs.class"
    :aid="
      value !== undefined
        ? `${aid}_OPTION_${optionKey(value).toString()}`
        : undefined
    "
  >
    <!-- Checkbox (left) -->
    <span
      v-if="checkboxes"
      class="size-4 shrink-0"
      :class="selected ? 'bg-checkbox-checked' : 'bg-checkbox-unchecked'"
      aria-hidden="true"
    />

    <span class="grow truncate">
      <slot />
    </span>

    <slot name="append" :selected="selected">
      <!-- Checkmark (right) -->
      <span
        v-if="!checkboxes && selected"
        class="fa-regular fa-check order-last"
        aria-hidden="true"
      />
    </slot>
  </ListboxOption>
</template>
