<script setup lang="ts">
import { QBtn } from 'quasar';
import { computed } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    color?: 'primary' | 'primary-300' | 'gray-400' | 'white';
    disabled?: boolean;
    href?: string; // eslint-disable-line vue/require-default-prop
    loading?: boolean;
    size?: 'sm' | 'md';
    target?: string; // eslint-disable-line vue/require-default-prop
    type?: 'submit' | 'reset' | 'button'; // eslint-disable-line vue/require-default-prop
    variant?: 'contained' | 'text';
    aid: string;
  }>(),
  {
    color: 'primary',
    size: 'md',
    variant: 'contained',
  }
);

const emit = defineEmits<{
  click: [event: PointerEvent];
}>();

const colors = computed(() => {
  if (props.variant === 'contained') {
    switch (props.color) {
      case 'primary-300':
        return {
          text: 'primary',
          background: 'primary-300',
        };
      case 'gray-400':
        return {
          text: 'gray-400',
          background: 'primary-50',
        };
      case 'white':
        return {
          text: 'white',
          background: 'gray-500',
        };
      case 'primary':
      default:
        return {
          text: 'white',
          background: 'primary',
        };
    }
  }

  return {
    text: props.color,
    background: props.color,
  };
});
</script>

<template>
  <QBtn
    :class="[
      'ws-icon-button',
      {
        'ws-icon-button--small': size === 'sm',
        'ws-icon-button--medium': size === 'md',
      },
      $attrs.class,
    ]"
    unelevated
    :ripple="false"
    :disable="disabled"
    round
    :loading="loading"
    :href="href"
    :target="target"
    :type="type"
    :flat="variant === 'text'"
    :color="colors.background"
    :text-color="colors.text"
    :aid="aid"
    @click="emit('click', $event as PointerEvent)"
  >
    <slot />
  </QBtn>
</template>

<style lang="scss" scoped>
.ws-icon-button {
  min-width: var(--ws-icon-button-size);
  min-height: var(--ws-icon-button-size);
  font-size: var(--ws-icon-button-font-size);

  &--small {
    --ws-icon-button-size: 26px;
    --ws-icon-button-font-size: 1rem;
  }

  &--medium {
    --ws-icon-button-size: 32px;
    --ws-icon-button-font-size: 1.125rem;
  }
}
</style>
