<script lang="ts">
let wsRadioId = 0;
</script>

<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
});

const checked = defineModel<unknown>();

defineProps<{
  value: unknown;
  label: string;
  disabled?: boolean;
  aid: string;
}>();

const id = `ws-radio-${wsRadioId++}`;
</script>

<template>
  <div class="flex items-center">
    <input
      :id="id"
      v-model="checked"
      type="radio"
      :value="value"
      :disabled="disabled"
      class="peer size-[14px] appearance-none border-gray-300 bg-radio-unchecked text-primary checked:bg-radio-checked focus:ring-primary disabled:bg-radio-unchecked-disabled disabled:!opacity-100 disabled:checked:bg-radio-checked-disabled"
      :aid="aid"
    />

    <label :for="id" class="ms-1.5 block text-base peer-disabled:text-gray-400">
      {{ label }}
    </label>
  </div>
</template>
