import { InjectionKey, Ref } from 'vue';

// This is the type that the `ListboxOption` component expects for its `value` prop.
export type OptionValue = string | number | boolean | object | null;

export const wsSelectInjectionKey = Symbol() as InjectionKey<{
  modelValue: Readonly<Ref<OptionValue>>;
  optionLabel: Readonly<Ref<(value: OptionValue) => string>>;
  optionKey: Readonly<Ref<(value: OptionValue) => PropertyKey>>;
  label: Readonly<Ref<string | undefined>>;
  labelPosition: Readonly<Ref<'inside' | 'outside'>>;
  variant: Readonly<Ref<'outlined' | 'filter' | 'text'>>;
  size: Readonly<Ref<'md' | 'lg'>>;
  multiple: Readonly<Ref<boolean>>;
  checkboxes: Readonly<Ref<boolean>>;
  disabled: Readonly<Ref<boolean>>;
  readonly: Readonly<Ref<boolean>>;
  loading: Readonly<Ref<boolean>>;
  searchInput: Readonly<Ref<HTMLInputElement | null>>;
  hasError: Readonly<Ref<boolean>>;
  aid: Readonly<Ref<string>>;

  clear(): void;
  removeSelection(value: OptionValue): void;
}>;
