<script setup lang="ts">
import { inject } from 'vue';

import { wsSelectInjectionKey } from './ws-select-injection-key';

defineOptions({
  inheritAttrs: false,
});

const query = defineModel<string>();

const context = inject(wsSelectInjectionKey);

if (!context) {
  throw new Error(
    'WsSelectSearchInput must be used within a WsSelect component'
  );
}

const { searchInput, aid } = context;
</script>

<template>
  <div class="relative mx-2.5 mt-2.5">
    <div
      class="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-2"
    >
      <span class="fa-regular fa-search text-gray-400" aria-hidden="true" />
    </div>

    <input
      ref="searchInput"
      v-model="query"
      type="text"
      placeholder="Search…"
      class="h-[45px] w-full rounded pe-2 ps-[30px] outline-none ring-1 ring-inset ring-gray-300 placeholder:font-semibold placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm"
      :aid="`${aid}_SEARCH`"
      @keydown.space.stop
    />
  </div>
</template>
