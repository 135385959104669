<script setup lang="ts">
import WsButton from '../button/ws-button.vue';
import { IDialog, WsDialogSection, WsDialogActions } from '../dialog';
import { PromptOptions, PromptResult } from './prompt.types';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  dialog: IDialog<PromptOptions, PromptResult>;
}>();

const {
  statement,
  question,
  primaryButtonColor,
  primaryButtonText,
  secondaryButtonText,
  width,
  aid,
  separators,
} = props.dialog.input;
const finalWidth = width || 'auto';
const showBottomSeparator =
  separators === undefined || separators === 'both' || separators === 'bottom';
</script>

<template>
  <div
    :style="{
      width: finalWidth,
      maxWidth: '100%',
      display: 'flex',
      'flex-direction': 'column',
      overflow: 'auto',
    }"
  >
    <WsDialogSection :style="{ 'white-space': 'pre-line', overflow: 'auto' }">
      <div>{{ statement }}</div>
      <div>{{ question }}</div>
    </WsDialogSection>
    <WsDialogActions :separator="showBottomSeparator">
      <WsButton
        :label="secondaryButtonText"
        :aid="aid + '_SECONDARY'"
        color="gray-500"
        variant="outlined"
        @click="props.dialog.close(PromptResult.Secondary)"
      />

      <WsButton
        :label="primaryButtonText"
        :aid="aid + '_PRIMARY'"
        :color="primaryButtonColor || 'primary'"
        @click="props.dialog.close(PromptResult.Primary)"
      />
    </WsDialogActions>
  </div>
</template>
