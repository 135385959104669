import { Notify } from 'quasar';
import {
  ToastStatus,
  ToastTimeout,
  ToastActionOption,
  ToastAction,
  IUpdatableToast,
  ToastOptions,
  statusMap,
} from './toast.types';

export { ToastStatus } from './toast.types'; // used in local-dev-mfe.ts

/**
 * Display a floating message to the user
 * [Figma](https://www.figma.com/design/FOKSeotkMONlj0dFEyNf4S/Design-system?node-id=5685-5513&node-type=text&m=dev) &
 * [Prd](https://docs.google.com/document/d/1cLT9jJOLbiXB3vw7R9aWEsLIR-RPRPLAFeULOkg5gzo/edit?tab=t.0#heading=h.shzqrferfmam)
 * @param options See ToastOptions type for more details
 */
export function toast(options: ToastOptions): IUpdatableToast {
  const { status, aid, blockActions } = options;
  const { color, icon } = statusMap[status || ToastStatus['Completed']];

  let timeout: number = options.timeout || ToastTimeout.Default;
  let loading: boolean = false;

  if (status === ToastStatus.Loading) {
    timeout = 0;
    loading = true;
  }

  // close button always appeare in the toast!
  const closeAction: ToastActionOption = {
    label: options.closeButtonText || 'close',
    handler() {
      options.onClose ? options.onClose() : () => {};
    },
  };

  const actions: ToastAction[] = (options.actions || [])
    .concat(closeAction)
    .map((a) => {
      return {
        size: 'md',
        padding: '0',
        color: 'white',
        iconRight: a.icon,
        label: a.label,
        noDismiss: a.noDismiss,
        handler: a.handler,
      };
    });

  const notify = Notify.create({
    message: options.message,
    icon,
    iconSize: '16px',
    color,
    timeout,
    multiLine: blockActions,
    spinner: loading,
    position: 'bottom-left',
    group: false,
    classes: `ws-toast ${loading ? 'ws-toast__loading' : ''}`,
    actions,
    attrs: {
      aid,
      style: `${loading ? '--notify-progress: 1%;' : ''}`,
    },
  });

  // progress = precentage (1-100), mininum is 1 and not 0 to keep a minimal visible indication
  const updateProgress = (progress: number) => {
    progress = progress > 100 ? 100 : progress < 1 ? 1 : progress;
    notify({
      attrs: {
        style: `--notify-progress: ${progress}%;`,
      },
    });

    if (progress === 100) {
      notify({
        timeout: 100,
      });
    }
  };

  const updateMessage = (message: string) => {
    notify({
      message,
    });
  };

  const dismiss = () => {
    notify({
      timeout: 1,
    });
  };

  return {
    updateProgress,
    dismiss,
    updateMessage,
  };

  // FYI: see style.scss for overrides
}
