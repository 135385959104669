<script setup lang="ts">
import { Switch } from '@headlessui/vue';

defineOptions({
  inheritAttrs: false,
});

const enabled = defineModel<boolean>();

defineProps<{
  disabled?: boolean;
  aid: string;
}>();
</script>

<template>
  <Switch
    v-model="enabled"
    :disabled="disabled"
    :class="
      disabled
        ? 'bg-gray-100'
        : 'ui-checked:bg-primary ui-not-checked:bg-gray-200'
    "
    class="relative inline-flex h-[16px] w-[30px] shrink-0 cursor-pointer rounded-full border border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-1 focus-visible:ring-primary focus-visible:ring-offset-1 disabled:!opacity-100"
    :aid="aid"
  >
    <span
      aria-hidden="true"
      :class="enabled ? 'translate-x-[calc(16px-2px)]' : 'translate-x-0'"
      class="pointer-events-none inline-block size-[14px] transform rounded-full bg-white shadow-[0_0_4px_rgba(0,0,0,0.1)] ring-0 transition duration-200 ease-in-out"
    />
  </Switch>
</template>
