/**
 * Statuses as defined in figma\
 * https://www.figma.com/design/FOKSeotkMONlj0dFEyNf4S/Design-system?node-id=4951-1683&node-type=canvas&m=dev
 */
export enum ToastStatus {
  Completed = 'Completed',
  Error = 'Error',
  Loading = 'Loading',
}

/**
  MS to disimss the toast
*/
export enum ToastTimeout {
  Short = 4000,
  Default = 8000,
  Long = 16000,
}

/**
  the options that can be passed to a toast action

  icon - font-awasome icon\
  label - text of the button\
  noDismiss - prevent the button from closing the toast\
  handler - callback for the action click
*/
export type ToastActionOption = {
  icon?: string;
  label?: string;
  noDismiss?: boolean;
  handler?: () => void;
};

/**
  the actual params that are passed to a toast action
*/
export type ToastAction = {
  iconRight?: string;
  label?: string;
  noDismiss?: boolean;
  handler?: () => void;
  size?: string;
  padding?: string;
  color?: string;
};

/**
  * object of functions that will be returned from the toast\
  * for controling the toast from outside after it was popped
  *
  * updateProgress - update the percentage of the progress bar\
  * updateMessage - change the text message of the toast\
  * dismiss - force closing the toast
  ```ts
      const myToast = toast(...)
      myToast.updateProgress(50)
      myToast.updateMessage('Done')
  ```
*/
export interface IUpdatableToast {
  updateProgress(progress: number): void;
  updateMessage(message: string): void;
  dismiss(): void;
}

export type ToastOptions = {
  /** Automation ID */
  aid: string;

  /** Message to display */
  message: string;

  /**
   * The good/bad/ugly - see the enum\
   */
  status?: ToastStatus;

  /**
   * How long to display the toast
   */
  timeout?: ToastTimeout;

  /**
   * Add buttons to the default Close button (see ToastActionOption for options)
   */
  actions?: ToastActionOption[];

  /**
   * If true then the actions will drop line and appear at the bottom section of the toast beneath the message.\
   * (actions will be inline by default, make sure you have enougth space to contain them!!)
   */
  blockActions?: boolean;

  /**
   * The text of the close button.\
   * defult text is "Close"\
   * (close button will appear in every toast!)
   */
  closeButtonText?: string;

  /**
   * a callback for the default close button
   */
  onClose?: () => void;
};

type StatusProps = {
  color: string;
  icon: string;
};

export const statusMap: Record<ToastStatus, StatusProps> = {
  [ToastStatus.Completed]: {
    color: 'grey-500',
    icon: 'fa-regular fa-check',
  },
  [ToastStatus.Error]: {
    color: 'danger',
    icon: 'fa-regular fa-circle-exclamation',
  },
  [ToastStatus.Loading]: {
    color: 'grey-500',
    icon: '',
  },
};
