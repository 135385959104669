<script setup lang="ts">
import { QBadge } from 'quasar';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    label: string;
    size?: 'sm' | 'md';
    icon?: string; // eslint-disable-line vue/require-default-prop
    align?: 'top' | 'middle' | 'bottom';
    aid: string;
  }>(),
  {
    align: 'middle',
    size: 'md',
  }
);
</script>

<template>
  <QBadge
    :class="[
      'ws-badge',
      {
        'ws-badge--small': props.size === 'sm',
        'ws-badge--medium': props.size === 'md',
      },
      $attrs.class,
    ]"
    :style="$attrs.style"
    :align="props.align"
    color="primary-300"
    text-color="primary"
    :aid="props.aid"
    :label="label"
  >
    <span
      v-if="props.icon"
      :class="['ws-badge__icon', props.icon]"
      aria-hidden="true"
    />
  </QBadge>
</template>

<style lang="scss" scoped>
.ws-badge {
  // defaults as for medium size
  --ws-badge-font-size: 0.625rem;
  --ws-badge-padding: 4px 10px;
  --ws-badge-icon-size: 0.75rem;

  font-weight: 700;
  line-height: 0.875rem;
  border-radius: 8px;
  font-size: var(--ws-badge-font-size);
  padding: var(--ws-badge-padding);

  &__icon {
    margin-inline-start: 4px;
    font-size: var(--ws-badge-icon-size);
  }

  &--small {
    --ws-badge-font-size: 0.5625rem;
    --ws-badge-padding: 2px 8px;
    --ws-badge-icon-size: 0.625rem;
  }
}
</style>
