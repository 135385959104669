import { Dialog } from 'quasar';
import { DialogOptions } from './dialog.types';
import WsDialog from './ws-dialog.vue';

export async function openDialog<TInput = void, TOutput = void>(
  options: DialogOptions<TInput>
): Promise<TOutput | undefined> {
  return new Promise((resolve) => {
    Dialog.create({
      component: WsDialog,
      componentProps: {
        options,
      },
    })
      .onOk((output) => {
        resolve(output);
      })
      .onCancel(() => {
        resolve(undefined);
      })
      .onDismiss(() => {
        resolve(undefined);
      });
  });
}

export type AbortableDialog<TOutput> = {
  result: Promise<TOutput>;
  abort: () => void;
};

/**
 * A closable version of the dialog\
 * Initially created to support storybook
 */
export function openAbortableDialog<TInput = void, TOutput = void>(
  options: DialogOptions<TInput>
): AbortableDialog<TOutput | undefined> {
  const dialog = Dialog.create({
    component: WsDialog,
    componentProps: {
      options,
    },
  });

  const result = new Promise<TOutput | undefined>((resolve) => {
    dialog
      .onOk((output) => {
        resolve(output);
      })
      .onCancel(() => {
        resolve(undefined);
      })
      .onDismiss(() => {
        resolve(undefined);
      });
  });

  const abort = () => {
    dialog.hide();
  };

  return {
    result,
    abort,
  };
}
