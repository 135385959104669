export enum PromptResult {
  Dismiss = 'Dismiss',
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export type PromptOptions = {
  /**
   * ID used for component and e2e testing
   *
   * Placed on the dialog itself and on the buttons\
   * Buttons IDs are postfixed:
   * - `_PRIMARY`
   * - `_SECONDARY`
   * - `_DISMISS` for the x button
   */
  aid: string;

  /** Title text */
  header: string;

  /**
   * Which separators to show if any\
   * Default is 'bottom'
   */
  separators?: 'none' | 'top' | 'bottom' | 'both';

  /**
   * First line of the content\
   * This is usually a statement stating the situation for which the user needs to make a decision\
   * "You are about to delete 10 signatures"
   *
   * Can be multiline text
   */
  statement?: string;

  /**
   * The question part of the content\
   * Appears below the `statement`, if used
   * "Are you sure?"
   *
   * If the prompt only needs a single line of text, use this prop
   *
   * Can be multiline text
   */
  question: string;

  /* Text for the primary button */
  primaryButtonText: string;

  /** Default for the primary button is `primary` */
  primaryButtonColor?: 'primary' | 'promotion' | 'danger';

  /**
   * Text for the secondary button
   */
  secondaryButtonText: string;

  /**
   * Forced width style for the prompt\
   * Used as an inline style\
   * Don't forget to include units - e.g. '300px'
   *
   * Default: 'auto'
   */
  width?: string;

  /** Force the user to make a choice */
  persistent?: boolean;
};
