<script setup lang="ts">
import { QTooltip, QTooltipProps } from 'quasar';
import { computed } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    delay?: number;
    maxWidth?: string;
    position?: 'top' | 'right' | 'bottom' | 'left';
    aid: string;
    size?: 'sm' | 'md';
  }>(),
  {
    position: 'top',
    delay: 200,
    maxWidth: '250px',
    size: 'md',
  }
);

const position = computed(() => {
  switch (props.position) {
    case 'right':
      return {
        anchor: 'center end',
        self: 'center start',
      };
    case 'bottom':
      return {
        anchor: 'bottom middle',
        self: 'top middle',
      };
    case 'left':
      return {
        anchor: 'center start',
        self: 'center end',
      };
    case 'top':
    default:
      return {
        anchor: 'top middle',
        self: 'bottom middle',
      };
  }
});
</script>

<template>
  <QTooltip
    :class="[
      'ws-tooltip',
      'bg-gray-500',
      {
        'ws-tooltip--size-small': props.size === 'sm',
        'ws-tooltip--size-medium': props.size === 'md',
      },
    ]"
    :offset="[6, 6]"
    :delay="props.delay"
    :aid="props.aid"
    :max-width="props.maxWidth"
    :anchor="position.anchor as QTooltipProps['anchor']"
    :self="position.self as QTooltipProps['self']"
    transition-show="fade"
    transition-hide="fade"
  >
    <slot />
  </QTooltip>
</template>

<style lang="scss">
.ws-tooltip {
  --ws-tooltip-padding: 6px 10px;
  --ws-tooltip-font-size: 0.875rem;

  padding: var(--ws-tooltip-padding);
  border-radius: 8px;
  font-size: var(--ws-tooltip-font-size);
  // TODO: apply system shadows once added
  box-shadow: var(--box-shadow);

  &--size-small {
    --ws-tooltip-padding-block: 4px 8px;
    --ws-tooltip-font-size: 0.75rem;
  }
}
</style>
