<script setup lang="ts">
import { ListboxOptions } from '@headlessui/vue';
import { vIntersectionObserver } from '@vueuse/components';
import { useScrollLock } from '@vueuse/core';
import { inject, ref } from 'vue';

import { wsSelectInjectionKey } from './ws-select-injection-key';

defineOptions({
  inheritAttrs: false,
});

const context = inject(wsSelectInjectionKey);

if (!context) {
  throw new Error('WsSelectPanel must be used within a WsSelect component');
}

const { searchInput, aid } = context;

const position = ref<'top' | 'bottom'>('bottom');

const scrollLock = useScrollLock(document.body);

function intersectionCallback([entry]: IntersectionObserverEntry[]) {
  const visible = entry.target.checkVisibility();

  scrollLock.value = visible;

  // If the panel element becomes hidden, reset the position to bottom.
  if (!visible) {
    position.value = 'bottom';
    return;
  }

  // If the current position is bottom, and the panel is not fully visible,
  // change the position to top.
  // For some reason, the intersection ratio may be less than 1 even when the
  // panel is fully visible, so we use 0.99 as the threshold.
  if (position.value === 'bottom' && entry.intersectionRatio < 0.99) {
    position.value = 'top';
  }
}
</script>

<template>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-from-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-75 ease-out"
    leave-from-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
  >
    <ListboxOptions
      v-intersection-observer="intersectionCallback"
      as="div"
      class="absolute z-10 min-w-full rounded bg-white text-base shadow focus:outline-none"
      :class="[
        {
          'bottom-[calc(100%+6px)]': position === 'top',
          'top-[calc(100%+6px)]': position === 'bottom',
        },
        $attrs.class,
      ]"
      :aid="`${aid}_PANEL`"
      @focus="searchInput?.focus()"
    >
      <slot />
    </ListboxOptions>
  </transition>
</template>
