import { openDialog } from '../dialog';
import { AbortableDialog, openAbortableDialog } from '../dialog/dialog';
import { PromptOptions, PromptResult } from './prompt.types';
import WsPrompt from './prompt-dialog.vue';

/**
 * Ask the user a simple yes/no question
 * @param options See `PromptOptions`
 * @returns The users' choice
 */
export async function prompt(options: PromptOptions): Promise<PromptResult> {
  const res = await openDialog<PromptOptions, PromptResult>({
    aid: options.aid,
    headerText: options.header,
    showTopSeparator:
      options.separators === 'both' || options.separators === 'top',
    persistent: options.persistent,
    component: WsPrompt,
    input: options,
  });

  return res || PromptResult.Dismiss;
}

export type AbortablePrompt = AbortableDialog<PromptResult | undefined>;

/**
 * A closable version of the simple prompt\
 * Initially created to support storybook
 */
export function abortablePrompt(options: PromptOptions): AbortablePrompt {
  const res = openAbortableDialog<PromptOptions, PromptResult>({
    aid: options.aid,
    headerText: options.header,
    showTopSeparator:
      options.separators === 'both' || options.separators === 'top',
    persistent: options.persistent,
    component: WsPrompt,
    input: options,
  });

  return res;
}
